import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "E2Eテストの信頼性と向き合ってMagicPodのヘルススコアが100に達した話",
  "date": "2024-08-30T05:47:08.000Z",
  "slug": "entry/2024/08/30/144708",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "E2Eテストの信頼性と向き合ってMagicPodのヘルススコアが100に達した話"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Edit here! */
    }
    <p>{`こんにちは、医療プラットフォーム本部 プロダクト開発室 QA グループ所属の小島大周 (`}<a parentName="p" {...{
        "href": "https://x.com/daishu1130?s=11&t=_aFmdhMTTjU9g5_qC7qJnw"
      }}>{`@Daishu`}</a>{`) です。2022 年 4 月に QA エンジニアとしてメドレーに入社しました。主に "`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`クラウド診療支援システム CLINICS`}</a>{`" のプロダクト開発における QA と、E2E テスト自動化を担当しています。`}</p>
    <p>{`今回は、MagicPod という自動テストツールに半年間向き合い、テスト内容と結果の信頼性を高める改善を行った話をさせていただきます。`}</p>
    <p>{`※ 自動テストツール全般に共通する話なので、自動テストに携わっている方々にご覧いただけると大変嬉しいです。`}</p>
    <h1>{`MagicPod`}</h1>
    <p>{`弊社は E2E テストの自動化に対して `}<a parentName="p" {...{
        "href": "https://magicpod.com/"
      }}>{`MagicPod`}</a>{` というツールを採用しています。MagicPod の詳細は割愛しますが、導入時の話は「`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2021/01/15/180126"
      }}>{`UIテストの自動化に MagicPod を導入した話`}</a>{`」にありますので、ぜひご覧ください。`}</p>
    <h1>{`活用状況と背景`}</h1>
    <p>{`私が所属する医療プラットフォーム本部では、医療機関に向けて予約や問診、診察 (電子カルテ)、会計等の機能を提供する "`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`クラウド診療支援システム CLINICS`}</a>{`" と、患者向けにオンライン診療をはじめ、総合的な医療体験を提供する "`}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`オンライン診療・服薬指導アプリ CLINICS`}</a>{`" を展開しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/75526/2024_8_30_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABqklEQVQoz2WQXWvTYBiG+4v8B/4h/4I7VMQDDzbwTAadigxEEIUqTC3bDsJAtHP9WNvVtU2TNB9vkibNR9/kvaSJips3PDyfXNw8DaUbUBQUXztsWkcEhom/MDDGVwh9QRnFZH5AGCdk6wT38R7+x8/YUYTruISriHwjkUWBH0Y0Clew1eaiT9I+ZWGadPt9tLMzpvN5tculJC8VrBPEnbv4D58wCwST4ZBer4dhGChVEicpjdL1UNTKFHgiIM9l1adZVmUpJUmaV/X66prCD2oTm/pOqZoQxcnWoVc1hZR8aLX40m5z8eOcb90R93Z2efX2EyKIcETIcDJnZ+8FT1++w7Ztjk9OsCyLP6qBTg3M0pRms4mmaZx3vtPpjrj/aJ83rVM8f8UqjjGWSx7sPmf/sIVrOQz6A6az6U1g+dshSnFb7fcH5GH9x8N+zLNL0IVNGAdoR6/ZBDWsLIt/gJ74C1C3YuyMMMWs2s09iTaxyLIEy18yMHtY7s8bBuJ1SkNejil0g2Km/xcYDko3Sa5n+EKQxBFesEIEK6IoQwQxnh9WL9nOTNvjF8ldUqaP9YgCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 1",
            "title": "2024 8 30 1",
            "src": "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/c1b63/2024_8_30_1.png",
            "srcSet": ["/static/cc6d8ef9fc0b95dc33e7d7e660582c34/5a46d/2024_8_30_1.png 300w", "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/0a47e/2024_8_30_1.png 600w", "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/c1b63/2024_8_30_1.png 1200w", "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/d61c2/2024_8_30_1.png 1800w", "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/97a96/2024_8_30_1.png 2400w", "/static/cc6d8ef9fc0b95dc33e7d7e660582c34/75526/2024_8_30_1.png 4360w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`私は画像左側の医療機関向けのプロダクトにおいて社内で `}<strong parentName="p">{`周辺領域`}<sup parentName="strong" {...{
          "id": "fnref-1"
        }}><a parentName="sup" {...{
            "href": "#fn-1",
            "className": "footnote-ref"
          }}>{`1`}</a></sup>{` `}</strong>{` と呼ぶ機能群 を中心としたQA・テスト自動化を担当しています。MagicPod は導入して4年目になりますが、半年前までこの周辺領域の機能に関しては、MagicPod を十分活用しきれておらず、以下のような状況でした。`}</p>
    <ul>
      <li parentName="ul">{`MagicPod の自動テスト実行頻度が週 1 回のみ`}</li>
      <li parentName="ul">{`自動化したリグレッションテストが 3 件しかない (`}<a parentName="li" {...{
          "href": "https://speakerdeck.com/magicpod/magicpodnotesutozi-dong-hua-herususukoahadouyatutejue-marunoka?slide=15"
        }}>{`20件が推奨値`}</a>{`)`}</li>
      <li parentName="ul">{`テスト結果が不安定で、自動テストの修正にかかる調査コストが大きい`}</li>
    </ul>
    <p>{`また、MagicPod には "`}<a parentName="p" {...{
        "href": "https://prtimes.jp/main/html/rd/p/000000030.000027392.html"
      }}>{`ヘルススコア機能`}</a>{`" がありますが、当時のヘルススコアは 30 点と低い数字でした。これは端的に言うと「`}<strong parentName="p">{`自動テストの信頼性が低い`}</strong>{`」という状態です。一方で、画像右側の CLINICS アプリ (患者向け) は MagicPod を十分活用できており、既にヘルススコアも 100 点に達していました。それゆえに、CLINICS (医療機関向け) の自動テストの改善が望まれていました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7fbbb686f4a732acbe522045e8eab67b/e9d03/2024_8_30_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABTUlEQVQoz32SS0/bQBSF8/9/RXcsEYvSDYtuilQEldpKoKYiQWBju8GxnbFn7rzyoZkQhCLo4uhcXc095z5mVpYldV3TNA1VVWWkXN/3eOewxmCtxaXYWkRs5o8w897nx4kP4WLEAy4ErEjObbdbYoyEEDKnWufsCztm7zqJMIlFXX1n8/kYGXoC0K3XLBYLimI3VVEUmDyBw4hFUoeHYsllHEe0CFYM+voX/dGnbCLtitu/d1zerpgXLTf3K+aPA8t/imWjeHgame1b3Yslx8kYnNqg5ze4EBkvzhl/XBDVwOOy4E8zsWgUP+97zudPVOtpVyv2HUGt0Wnx3ZrN2SlSV+jr36hvX/Ex0rUddafpR0O70ZTtyDBJPmDe4aFg4jTy7ggBBwxfTjDlQ469dwTvskCK40v8euW94FtREcmiCUopjFI47//7Xfb1z7qtZu36R41aAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 4",
            "title": "2024 8 30 4",
            "src": "/static/7fbbb686f4a732acbe522045e8eab67b/c1b63/2024_8_30_4.png",
            "srcSet": ["/static/7fbbb686f4a732acbe522045e8eab67b/5a46d/2024_8_30_4.png 300w", "/static/7fbbb686f4a732acbe522045e8eab67b/0a47e/2024_8_30_4.png 600w", "/static/7fbbb686f4a732acbe522045e8eab67b/c1b63/2024_8_30_4.png 1200w", "/static/7fbbb686f4a732acbe522045e8eab67b/d61c2/2024_8_30_4.png 1800w", "/static/7fbbb686f4a732acbe522045e8eab67b/97a96/2024_8_30_4.png 2400w", "/static/7fbbb686f4a732acbe522045e8eab67b/e9d03/2024_8_30_4.png 3794w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div style={{
      "textAlign": "center",
      "fontStyle": "italic",
      "color": "#2E3C4D",
      "marginBottom": "32px"
    }}>
当時の CLINICS (医療機関向け) のヘルススコア
    </div>
    <h1>{`MagicPod ヘルススコア機能`}</h1>
    <p>{`自動テストの改善について話す前に、MagicPod が提供しているヘルススコア機能について説明します。ヘルススコア機能とは、自動テストの活用度合いや成功率などを 100 点満点でスコアリングしたもので、2023 年 12 月にリリースされました。`}</p>
    <p>{`ざっくりとした採点基準の内訳と配点は下記となっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "683px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ff997807da36a8f4ad2a4170bd530b0e/bca35/2024_8_30_7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABqklEQVQoz42R20sbURDG9x8XLw+CmDwUFH2Q1SKWgm2KJIpR1OQhFSs+uNK0GyUbssmamOz9fssnM9sIfREHPmaGuZzfOUcob33F2sYBVsp7WC6JWC7tshbXRax+2of45Rj7h3Vs7f3AwtrOW32pJPLM0rqIzd0Ktj8fcSxU6y1Ujpv4XmvgW61R+GqhykkTp5c3qDd+oXb+k2v/9XHvFWpnLZxcXOOwegUB/0zXTWjaGEpvgMlEh6KoUFUN3a4KSZIhywo6jwr66jNGownaf54wm814VtNeIHe6HAtpmiLLMvh+ANf14HkBgiCEbbvwgxCO63FsWg4sy4Hr+kiSlHvSLOPZIIzgOB7SNIMQxzHiJEFfHfIpg8GISTpPPaa5vZNwL/2F0htiqI0hPciI44Rp5oT9vobf7UdeLtBWoiRRI5ESASkMo0JRhCiK2buez3Gez5DnOYtyIqZYoCWUkOjKpmnDth1Mpjp0w+T3NAwL06nB8VAbwTQtppuL6vSuvHBOSKdYtsNEHzFaRJTkiZxuRnlBGBaE9EaGafPAvPk9UQ8Zfdb4pSB8BX80kNaDUZOSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 7",
            "title": "2024 8 30 7",
            "src": "/static/ff997807da36a8f4ad2a4170bd530b0e/bca35/2024_8_30_7.png",
            "srcSet": ["/static/ff997807da36a8f4ad2a4170bd530b0e/5a46d/2024_8_30_7.png 300w", "/static/ff997807da36a8f4ad2a4170bd530b0e/0a47e/2024_8_30_7.png 600w", "/static/ff997807da36a8f4ad2a4170bd530b0e/bca35/2024_8_30_7.png 683w"],
            "sizes": "(max-width: 683px) 100vw, 683px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div style={{
      "textAlign": "center",
      "fontStyle": "italic",
      "color": "#2E3C4D",
      "marginBottom": "32px"
    }}>
      <a href="https://speakerdeck.com/magicpod/leantodevopsnotamenie2etesutogadekirukoto?slide=16">引用元：LeanとDevOpsのために E2E テストができること</a>
    </div>
    <p>{`注目すべきは、「`}<strong parentName="p">{`テストから信頼性のあるフィードバックを早いサイクルで得られているか`}</strong>{`」を重視している点です。これは、手動テストの工数削減や、自動テストでの不具合検出数のような`}<strong parentName="p">{`費用対効果を意識した指標ではなく、開発生産性への貢献を意識した指標`}</strong>{`となっています。スコアが低いということは「テストに信頼性がなく必要なフィードバックが得られていない」ことを意味しており、自動テストが開発生産性に貢献できていない状態となります。`}</p>
    <p>{`私個人も、テストカバレッジが十分ある自動テストが統合ブランチでパスしているなら「開発ブランチで不具合を潰してマージされている」と考えますし、自動テストでは「`}<strong parentName="p">{`統合ブランチが stable であることを保証する`}</strong>{`」ことが、「不具合を見つけた件数」と並んで重要と捉えています。ヘルススコア機能がリリースされたことで、こういった活動も定量的に測定できるので重宝しています。`}</p>
    <h1>{`主に3つの改善を実施しました`}</h1>
    <p>{`本題である E2E 自動テストの改善について話していきます。約半年前から本腰をいれて自動テストの信頼性向上に取り組みました。`}</p>
    <h2>{`① デイリー実行で不安定なテストを解消した`}</h2>
    <p>{`早いサイクルでフィードバックを得るには、自動テストのデイリー実行は必須です。そもそも、ウィークリー実行にしていた理由は、テスト結果が不安定なケース (以下、Flaky test) が多数あり、不具合かどうか調査するコストが大きかったからです。実際、調査してもほとんどが Flaky test で自動テスト側の不備で終わることが多く、ここに時間を割くなら開発機能の QA に使いたいという考えが当時ありました。とはいえ、Flaky test と一口に言っても突き詰めると何かしらの原因があり、発生パターンは限定的です。テスト結果を信頼できるものにするためにも、デイリー実行で Flaky test と向き合いました。`}</p>
    <p>{`まず、デイリー実行すれば Flaky test となるパターンに遭遇できます。次に「なぜ Flaky test として失敗するのか？」を 1 件ずつ追求していくことで、見つけた "穴" を確実に塞いでいきます。そして、この活動を毎日繰り返すことで、堅牢で精度の高い自動テストを構築できました。これは信頼性のあるフィードバックを得る上でも重要な要素でした。今は「`}<strong parentName="p">{`自動テストはデイリー実行で育てるもの`}</strong>{`」という考えで、日々の自動テストの結果と向き合うことができています。`}</p>
    <h2>{`② テストケースの二重メンテナンスを止めた`}</h2>
    <p>{`テストカバレッジが十分でないと、毎日自動テストを動かしても「統合ブランチが stable である」と言い切ることはできません。信頼性を高める上で、テスト対象とする機能の充実化は必須要件でした。しかし、当時のリグレッションテストの自動化フローは、手動テスト用のケースをスプレッドシートで作成した上で自動化する流れとなっており、テストケースを二重メンテナンスする形となっていました。このように本来の必要以上の工数がかかってしまうことからテストカバレッジを素早く上げづらい状況がありました。`}</p>
    <p>{`手動テスト用のケースは、自動化後に触る機会がほとんどないにも関わらず、仕様変更などで自動テストに手を加えるたびにメンテナンスコストが発生します。もちろん、仕様キャッチアップ等の特定シーンで役立つ事はありますが、常にではありません。こういった運用コストの課題もあり、`}<strong parentName="p">{`テストケースの二重メンテナンスはしない方針`}</strong>{` に切り替えました。`}</p>
    <p>{`現在は下記フローで MagicPod のテスト実装のみ行なっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fe1711bf2783295658ef9ac09284488c/1d15f/2024_8_30_6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABVUlEQVQozz1RyVbCQBDM//+BAh4M0QN6IMABL/yEB1QUiSwJWWYymaxMUr5ujId+k+lUdVX1WPbTEqOHBW5tF4PxDENnhslsBef5hXsjZ467xwWGzhy39hSDsYub+ynsyRITd3Xl2S6fxLWMaZEqjaMfQecF8qJEIhRUliMIE0il0bYdqqpGpgvuK6WZI9MMKrt+92WZtkUiUniHAIdTCD+I4e19RLHEORIQMkNRVnzfbPeIRcpDt96RhaNEIowEY6mstm1ZhdzQD1Ity4rdkFtSreqGz1MQIc9LFEUFrQvUzYUxVJnOOYXVdR3KqmaCMQYkoPMSiVQM7AUITAm+f04QUrHgxRh226+CcBbtjRSpQRFoMEUkdQL0QMKQwzCW/44pLvP/cM3lAosuwTnB28bD+6eH9ceOH4hIFI/cEEakGV7XX9jtfRz9kIf3j0dDadd10+AXltIGzeAEwScAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 6",
            "title": "2024 8 30 6",
            "src": "/static/fe1711bf2783295658ef9ac09284488c/c1b63/2024_8_30_6.png",
            "srcSet": ["/static/fe1711bf2783295658ef9ac09284488c/5a46d/2024_8_30_6.png 300w", "/static/fe1711bf2783295658ef9ac09284488c/0a47e/2024_8_30_6.png 600w", "/static/fe1711bf2783295658ef9ac09284488c/c1b63/2024_8_30_6.png 1200w", "/static/fe1711bf2783295658ef9ac09284488c/d61c2/2024_8_30_6.png 1800w", "/static/fe1711bf2783295658ef9ac09284488c/97a96/2024_8_30_6.png 2400w", "/static/fe1711bf2783295658ef9ac09284488c/1d15f/2024_8_30_6.png 3964w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div style={{
      "textAlign": "center",
      "fontStyle": "italic",
      "color": "#2E3C4D",
      "marginBottom": "32px"
    }}>
リグレッションテストの自動化手順
    </div>
    <p>{`この方針により、約半年で `}<strong parentName="p">{`CLINICS の周辺領域に含まれる機能のテストカバレッジを 100% まで拡充`}</strong>{`できました。一方で、手動テスト用のケースがないことで「テストケースを使った仕様キャッチアップができない、そもそもどんな自動テストがあるのか外から見えづらい」とった課題が生まれましたが、MagicPod がテスト実行時に取得するキャプチャを用いた仕様書や、機能一覧と自動化したテストケースをマッピングした資料を用意することで対応しています。この取り組みについては、別途またご紹介できればと思います。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "492px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2f69fc38925572332a4d761de58f886d/5c6e9/2024_8_30_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "126.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAACXBIWXMAABYlAAAWJQFJUiTwAAAChklEQVQ4y5WViQ7aQAxE8///UvVXECAhBOG+SQhH7gNXz62jJaSVimRt4t2dHY9ng1dWlVRxLMVoLHWeSxCGcrvd5PV6SZIkcr1e5fF4yPP51GAuDEPJskzKspSiKDR4JryyaaQKAkl//BRJEpn5vkwmE1mtVrLf72UwGMhoNJLhcCjj8Vh2u52C5XneD8hLBcumkbpp5H6/K4s0TTWMGQFrcnVdf4C54dlEXuSS5ZlUdSVN0+gGWBBxHGv5ALEWUGPUDWVYlIWEaSS3LJJLEkpRl5KlmURRJJfLRXzfl/V6rYCwPJ/PveyUIWwI9/d+vzXHSNjPzdm+bngukCb/bOgLO8w9pPtTQEqjc//76ztUAfEauhyPRx1pAt4jHwSB+o7u04zT6aQE0KsF7TKkO4CwCXvQUTbD2kazF+voPA3qk6QtebvdKhNYGVPXvIwmvIH1SeDZAwDcAiyy2Wz0nUMOh4OWycg7c4vFoiXgytEyBND0AhTvAUiO8vGe+Y81JoMZnwpg3QIyyQbKYgKtyNEYNjOHtmwmxyF/67oCsoGSrCyYUdp0OlXG3BICdswTVuJXU+wBELTiCwMwgMvlUjUlxwcDIOQAmPVUYqAftqFz5sPZbKaAbHCZMccz63AFZbu35wOQBAvQiFIYzYtoasKTw4d9QF9Xz07GAowwIpABZjDGIkjBaF3t3m0FZNJ8iE6AoN98PldQ05Nnt+O9gPZivkM/MzUHYBMaQWNgSnNcdr0l4zlOtbubZulvzdL061tp38R/AlIGbCiV0fTjjwo9aQRrKJNDeXe/Nh+AljDdKIty0Q8LWancX5vjMNfYXwxJog0BMCM6MsLGPm1oDFP3r6AL+At6+4mRAtJMCAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 2",
            "title": "2024 8 30 2",
            "src": "/static/2f69fc38925572332a4d761de58f886d/5c6e9/2024_8_30_2.png",
            "srcSet": ["/static/2f69fc38925572332a4d761de58f886d/5a46d/2024_8_30_2.png 300w", "/static/2f69fc38925572332a4d761de58f886d/5c6e9/2024_8_30_2.png 492w"],
            "sizes": "(max-width: 492px) 100vw, 492px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div style={{
      "textAlign": "center",
      "fontStyle": "italic",
      "color": "#2E3C4D",
      "marginBottom": "32px"
    }}>
MagicPod のテスト概要欄の記載例
    </div>
    <h2>{`③ 先行して共有ステップを作成した`}</h2>
    <p>{`この改善が最も成果に結びつきました。`}<a parentName="p" {...{
        "href": "https://support.magic-pod.com/hc/ja/articles/4408910141977"
      }}>{`共有ステップ`}</a>{`とは MagicPod の標準機能で、テストステップを汎用的なステップ (関数化) として流用できるものです。一般的に、複数のテストで同一のステップがある場合、共有ステップを作成されると思いますが、私は現時点で同一ステップがなくても、`}<strong parentName="p">{`先行投資的に共有ステップを作成する方針`}</strong>{`で実装を進めました。これにより、早い段階で共有ステップを拡充できました。`}</p>
    <p>{`現在は、共有ステップをパズルのように組み上げる、実際のコーディングに近い実装スタイルを確立できました。実装スピードは格段に上がり、再発防止等で確認項目を追加する場合は 10 分ほど、ゼロから大きめの E2E テストを実装する場合でも 1-2 時間ほどで作成できます。`}</p>
    <p>{`実装スピードの向上はテストカバレッジを上げる上でも役立ちましたが、他のテストで動作保証できた共有ステップを使い回すことで「`}<strong parentName="p">{`デイリー実行で自動テストを育てる前から安定したテストを作りやすい`}</strong>{`」という点も大きかったです。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0676e197c2933c75ac24e7c29cb8643a/53d19/2024_8_30_5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACAElEQVQoz31STW/TQBD13yuHigNSAVFUcqhokRBwQG6+XLcqotdUiEalP4w0X4TG9tqxsx/+TA4PzYCjCFEOo9l9u/P2zduxnt4c4/GXBnavDrB3/Rp7/SM8+XqI3d4rNG4/MPasf4yDb++wf/MWz/tv0Lh9j73rI+z0XmCnt7+JR1cvYX2+vIRtn6DZaqPZbuOjbeOk2YJ7do7O6Skc10XXdTdrh9cOZ7qzHRcXn2CNxAzzKECsJSKZQKYGP2IPCyORryrERnFOCg1PhsiqErrIYKoCqsiwTDXSVQlT5ry33PMzhFEEk6ZQWnNIrRAnCbQxkEqhKArkRQGlFYwxMKlhrCxL5HnOmNYaaZbCsm0bk/EYQRAgyzPM/TlEIJBlGV9SfwjTNEVGjyqFJEmwWCz4vCaL45iz1Wq14Pk+IlJpDL9Ixev1GlVV8Z4IKWi/Wq02eH1GWI1bnU4HnudBa4OllghCwW1KKVlNTUztbedtkhrbEAZC/PZOSSzimNUSFoYht7ZcLpHl+abof2G12x2IMGTPqFCIEOPJBIO7OwyHQ3wfDNgSOt9W8iBht9uFEIL9I0JSNJvNMJlMMJ1OMRqNWCmR/d32Pwkdx+EfIr/ol+oxqD2kIHV1AflW+/qgwp/396yCiql93/d57uhzgkhg7ns8KvUdEkCTUH/KNuEvdbxS6xOfuMoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 5",
            "title": "2024 8 30 5",
            "src": "/static/0676e197c2933c75ac24e7c29cb8643a/c1b63/2024_8_30_5.png",
            "srcSet": ["/static/0676e197c2933c75ac24e7c29cb8643a/5a46d/2024_8_30_5.png 300w", "/static/0676e197c2933c75ac24e7c29cb8643a/0a47e/2024_8_30_5.png 600w", "/static/0676e197c2933c75ac24e7c29cb8643a/c1b63/2024_8_30_5.png 1200w", "/static/0676e197c2933c75ac24e7c29cb8643a/d61c2/2024_8_30_5.png 1800w", "/static/0676e197c2933c75ac24e7c29cb8643a/97a96/2024_8_30_5.png 2400w", "/static/0676e197c2933c75ac24e7c29cb8643a/53d19/2024_8_30_5.png 4210w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div style={{
      "textAlign": "center",
      "fontStyle": "italic",
      "color": "#2E3C4D",
      "marginBottom": "32px"
    }}>
共有ステップ例：Mailtrapに届いたメールの本文から正規表現に一致するテキストを取得
    </div>
    <h1>{`改善した結果どうなったか`}</h1>
    <p>{`一番の成果は、前日にマージされた Pull Request の不具合検出が毎朝行えるようになり、冒頭紹介した「テストから信頼性のあるフィードバックを早いサイクルで得られる」点が達成できたことです。また、テストカバレッジも十分あるので「統合ブランチが stable である」ことが保証でき、`}<strong parentName="p">{`機能開発や QA しやすい状況づくり (=開発生産性) にもコミットできている`}</strong>{`と思います。`}</p>
    <p>{`障害発生時には、MagicPod で再発防止策の自動テストを作るという手段を QA エンジニアだけで完結できるようになりました。これまで QA 側で対応できることは手動のリグレッションテストを追加する方法が多かったですが、`}<strong parentName="p">{`自動テストで再発を抑止するという解決手段を QA エンジニアが持てることは運用コスト的にも大きい`}</strong>{`です。`}</p>
    <p>{`これらの改善を通して、開発エンジニアの方々からの自動テストに対する信頼感が高まってきた、と感じることも増えてきました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95f8f5cfe0a32e13906eec3677338d95/c6320/2024_8_30_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABuklEQVQoz42R3W4SURSF5y1QasBSfjvIwAgIBToDrZoYaqmU2pLYWhNjmloJ+gokJFVDjCT8dCAi3nJXk/oKWr2k3mvU+BKfmUOtmGjai5V19k7OWmvvLaU1nezCItdvLJDUMoSvJIjEEvj8QSad3hFcPuwON1PuaZwe+QRmLXqCZdzeaSS7w0MoHEPPXEPLXEWfG3FKnycxm2YmlR5xUiee0IgnNcFBNYpfuSygqFECoSixeATJNukipc1xZ2OTfOE2yytrJ7yyWmS5sCre63fvCbORQASvrIgpxuGRg0jmSH5F5VIwjFcOIAdCqJE4ajgmnJXjJA6X788KnF5R/wvSuQk7tRcv+f7jJ8Ojz3z5+o2Dg3fs7j6l1d6j0Why/8EW5y9cFHv7/XFcfByS1ebg2fMaw+ER7z8ccvjxE/v7b6lWq3Q6Xer1OtsPS1is9r8E/5vQvF6p/Jh+v0+n26XVbtPrveZVr0e//4bBYMBOqYzFaju74KPyEwzDoNvp0Gw2MIw9KpUKzVabWq3G1vYOlokzJrRNeZifmaVQXCebL5K9uUYuv0luqchSLs/irYI4kml8mpgp+AvLbkbwyhLcVQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 8 30 3",
            "title": "2024 8 30 3",
            "src": "/static/95f8f5cfe0a32e13906eec3677338d95/c1b63/2024_8_30_3.png",
            "srcSet": ["/static/95f8f5cfe0a32e13906eec3677338d95/5a46d/2024_8_30_3.png 300w", "/static/95f8f5cfe0a32e13906eec3677338d95/0a47e/2024_8_30_3.png 600w", "/static/95f8f5cfe0a32e13906eec3677338d95/c1b63/2024_8_30_3.png 1200w", "/static/95f8f5cfe0a32e13906eec3677338d95/d61c2/2024_8_30_3.png 1800w", "/static/95f8f5cfe0a32e13906eec3677338d95/97a96/2024_8_30_3.png 2400w", "/static/95f8f5cfe0a32e13906eec3677338d95/c6320/2024_8_30_3.png 5030w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div style={{
      "textAlign": "center",
      "fontStyle": "italic",
      "color": "#2E3C4D",
      "marginBottom": "32px"
    }}>
障害の再発防止として自動テストを作成した際
    </div>
    <p>{`また先日にはMagicPod 社の`}<a parentName="p" {...{
        "href": "https://note.com/magicpod/n/n8d74743d75d5"
      }}>{`ミートアップイベント`}</a>{`に登壇する機会もいただきました。登壇時に利用したスライドは下記です。共有ステップの Tips や安定した Xpath ロケータを取得する方法などを紹介しているので、既に MagicPod を利用されている方はぜひご覧ください。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/eb8feeba29354cf1a4218d718e64bfa1" title="ヘルススコア100に到達した理由 / MagicPod Meetup Health score Night" allowFullScreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "100%",
      "height": "auto",
      "aspectRatio": "560 / 315"
    }} data-ratio="1.7777777777777777"></iframe>
    <h1>{`今後やりたいこと`}</h1>
    <p>{`上述した改善により、CLINICS (医療機関向け) の周辺領域における MagicPod のヘルススコアは 30 点から 100 点まで上げることができました。現在は、自動テストの新規追加や変更を加えた際にスコアが変動しないか注視しながら改善を継続しており、`}<strong parentName="p">{`デイリー実行する自動テストの安定稼働と拡張の両立`}</strong>{`ができています。`}</p>
    <p>{`今後もこのプロセスを崩さず、`}<strong parentName="p">{`CLINICS の基幹領域`}<sup parentName="strong" {...{
          "id": "fnref-2"
        }}><a parentName="sup" {...{
            "href": "#fn-2",
            "className": "footnote-ref"
          }}>{`2`}</a></sup>{` に対してもテストカバレッジを広げていきたい`}</strong>{`と考えています。まずは、基幹領域の中でE2Eで確認すべき機能の洗い出し、次に洗い出した機能の自動化タスクを個人にアサインできる体制づくりを検討しています。そのためにも、オンボーディングやレビュー体制、コーディングルール等の仕組みづくりもセットで考えていきます。`}</p>
    <p>{`また、`}<strong parentName="p">{`CLINICS に携わる開発の方々は実装しながらテストコードを書く素晴らしい文化`}</strong>{`がありますが、MagicPod の自動テストと一部重複して確認している箇所があります。テスト対象は重複しつつも、`}<strong parentName="p">{`テスト粒度の方針を定めて自動テスト全体の最適化`}</strong>{`にも取り組んでいく予定です。`}</p>
    <h1>{`おわりに`}</h1>
    <p>{`私が所属する医療プラットフォームの QA チームはいわゆる完全内製型で少数体制です。しかし、MagicPod の実装の容易さ、メンテナンスコストの低さにより少数でも複数のプロダクトを広範囲でカバーできています。組織体制にも大きく貢献できる MagicPod は素晴らしいツールです。この場をお借りして改めて感謝申し上げたいと思います。いつもありがとうございます。`}</p>
    <p>{`メドレーは、現在 QA エンジニアを募集しています。医療分野における高い品質目標と、いち早くお客様に価値提供するという課題の両立に対して、QA エンジニアだからできる解決策の打ち手を一緒に考えてコミットしていける方と是非お会いしたいです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://open.talentio.com/r/1/c/medley/pages/105574"
      }}>{`https://open.talentio.com/r/1/c/medley/pages/105574`}</a></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>
          <small>周辺領域とは、主に CLINICS の予約や問診、資格確認といった医療機関と患者との接点となるシステムを指す。社内での呼称。</small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-1",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>
          <small>基幹領域とは、主に CLINICS の受付・診察・会計といった業務において、電子カルテとレセコン(レセプトコンピュータの略。医療機関から健康保険組合などの支払い機関に対し、診療報酬を請求するために、レセプトと呼ばれる診療報酬明細書を作成するシステム)とのやりとりを行うシステムを指す。社内での呼称。 </small>
          <p parentName="li"><a parentName="p" {...{
              "href": "#fnref-2",
              "className": "footnote-backref"
            }}>{`↩`}</a></p>
        </li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      